<template>
  <div>
    <CCard>
      <CCardBody>
        <div class="number_of_people">
          <div>表示件数</div>
          <select v-model.number="numberOfPeople" name="" id="">
            <option value="5">5件</option>
            <option value="10">10件</option>
            <option value="50">50件</option>
            <option value="100">100件</option>
          </select>
        </div>
        <CDataTable
          class="mb-0 table-outline"
          hover
          :items="items"
          :fields="tableFields"
          :items-per-page="numberOfPeople"
          clickable-rows
          :active-page="activePage"
          @row-clicked="rowClicked"
          :pagination="{ doubleArrows: false, align: 'center' }"
        >
          <td slot="lastName" slot-scope="{ item }">
            <div class="name-ruby">{{ item.lastNameRuby }}</div>
            <div>{{ item.lastName }}</div>
          </td>
          <td slot="firstName" slot-scope="{ item }">
            <div class="name-ruby">{{ item.firstNameRuby }}</div>
            <div>{{ item.firstName }}</div>
          </td>
          <template #status="data">
            <td class="status">
              <CBadge :color="getBadge(data.item.status)">
                {{ data.item.status }}
              </CBadge>
            </td>
          </template>
          <template #support="data">
            <td class="status">
              <CBadge :color="getSupportBadge(data.item.support)">
                {{ data.item.support }}
              </CBadge>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import firebase from "firebase/app";

const db = firebase.firestore();
// timestamp形式のデータをいい感じの形式に変換する関数
const convertFromTimestampToDatetime = (timestamp) => {
  const _d = timestamp ? new Date(timestamp * 1000) : new Date();
  const Y = _d.getFullYear();
  const m = (_d.getMonth() + 1).toString().padStart(2, "0");
  const d = _d.getDate().toString().padStart(2, "0");
  const H = _d.getHours().toString().padStart(2, "0");
  const i = _d.getMinutes().toString().padStart(2, "0");
  const s = _d.getSeconds().toString().padStart(2, "0");
  return `${Y}/${m}/${d} ${H}:${i}`;
  // return `${Y}/${m}/${d} ${H}:${i}:${s}`;
};

export default {
  name: "UserList",
  components: {},
  data() {
    return {
      clientID: "",
      inviteCode: "",
      image: "",
      adminName: "",
      userRole: "",
      name: "",
      comment: "",
      posts: [],
      items: [],
      numberOfPeople: 50,
      activePage: 1,
      tableFields: [
        { key: "lastName", label: "性" },
        { key: "firstName", label: "名" },
        { key: "sex", label: "性別" },
        { key: "birthday", label: "年齢" },
        { key: "address", label: "居住地" },
        { key: "course", label: "コース" },
        { key: "numberOfTimes", label: "回数" },
        { key: "methodOfPayment", label: "支払い方法" },
        { key: "createdAt", label: "登録日" },
        { key: "status", label: "状態" },
        { key: "support", label: "サポート" },
      ],
    };
  },
  async created() {
    this.inviteCode = this.$store.state.clientInviteCode;
    await this.getUserTableData();
  },
  computed: {
    idToken() {
      return this.$store.getters.idToken;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    getBadge(status) {
      switch (status) {
        case "新着メッセージ":
          return "success";
        case "管理栄養士":
          return "info";
        case "":
          return "warning";
        case "未返信":
          return "danger";
        case "新規登録":
          return "primary";
        default:
          "primary";
      }
    },
    getSupportBadge(status) {
      if (this.userRole == "eatas") {
        switch (status) {
          case "d":
            return "success";
          case "問合せ中":
            return "info";
          case "a":
            return "warning";
          case "b":
            return "danger";
          case "c":
            return "primary";
          default:
            "primary";
        }
      } else {
        switch (status) {
          case "新着":
            return "success";
          case "a":
            return "info";
          case "b":
            return "warning";
          case "c":
            return "danger";
          case "d":
            return "primary";
          default:
            "primary";
        }
      }
    },
    userRegistration() {
      this.$router.push("user-registration");
    },
    rowClicked(id) {
      this.$store.commit("getUserData", id);
      this.$router.push("user-page");
      this.$store.dispatch("createMeasuredValue");
    },
    async getUserTableData() {
      await db
        .collection("users")
        .where("inviteCode", "==", this.inviteCode)
        .orderBy("lastNameRuby", "asc")
        .onSnapshot((querySnapshot) => {
          let userTable = [];
          querySnapshot.forEach((doc) => {
            let path = doc.data();
            userTable.push({
              // number: "",
              lastName: path.lastName,
              lastNameRuby: path.lastNameRuby,
              firstName: path.firstName,
              firstNameRuby: path.firstNameRuby,
              sex: path.sex,
              birthday: path.birthday,
              height: path.height,
              weight: path.weight,
              bmi: Math.round((path.weight / (path.height * 0.01) / (path.height * 0.01)) * 10) / 10,
              course: path.course,
              numberOfTimes: path.numberOfTimes,
              methodOfPayment: path.methodOfPayment,
              postalCode: path.postalCode,
              address: `${path.address1} ${path.address2}`,
              address1: path.address1,
              address2: path.address2,
              createdAt: convertFromTimestampToDatetime(path.createdAt.seconds),
              id: path.id,
              userImage: path.userImage,
              userType: path.userType,
              status: path.status,
              support: path.support,
            });
          });
          this.items = userTable;
        });
    },
  },
};
</script>
<style scoped>
.overlay {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.record-modal {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 2;
  padding: 1em;
  background: #fff;
  overflow-y: auto;
}
.record-modal::-webkit-scrollbar {
  display: none;
}
.number_of_people {
  display: flex;
  padding-bottom: 10px;
}
.number_of_people div {
  display: flex;
  align-items: center;
  padding: 2px 5px;
  border-left: solid 1px #ccc;
}
.number_of_people div:first-child {
  border-left: none;
}
select {
  border: solid 1px #d8dbe0;
  border-bottom: none;
  border-top: none;
}
.user-registration-btn {
  margin-left: 20px;
}
.status span {
  font-size: 0.9rem;
  height: 1.3rem;
}
.name-ruby {
  font-size: 0.5em;
}
.table th,
.table td {
  padding: 0.3rem;
}
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  padding: 0;
}
.card {
  margin-bottom: 0;
}
</style>
